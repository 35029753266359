<template>
  <div class="bg-white">
    <div class="mt-14 mb-10 md:my-14 max-w-lg mx-auto">
      <h1 class="font-quincy font-bold text-4xl md:text-5xl text-center">
        Destrave sua redação
      </h1>
      <p class="text-center md:text-lg mt-1">
        Alcance seu potencial máximo com acesso completo a plataforma.
      </p>

      <!-- Plans -->
      <div class="my-8 grid gap-2">
        <div
          v-for="plan in data?.plans"
          :key="plan.id"
          @click="selectPlan(plan)"
          class="border-[3px] rounded-full p-4 cursor-pointer flex items-center transition-all shadow"
          :class="{
            'opacity-90': plan.id !== currentPlanId,
            'border-primary bg-primary/5': plan.id === currentPlanId,
            'hover:border-primary/50': plan.id !== currentPlanId,
          }"
        >
          <VIcon
            icon="mdi-check-circle"
            :color="plan.id === currentPlanId ? 'primary' : 'accent'"
          />
          <div class="ml-2">
            <h3 class="text-base md:text-xl">
              {{ plan.planName }}
            </h3>
            <p class="text-xs md:text-sm">
              {{ plan.description }}
            </p>
          </div>

          <div class="ml-auto mr-2 text-right">
            <div class="font-semibold text-lg md:text-xl">
              {{ plan.maxInstallments }} x
              {{ formatCurrency((plan.price * (1 - (currentCoupon?.percentage ?? 0) / 100)) / plan.maxInstallments) }}
            </div>
            <div class="text-xs md:text-sm">
              Total de R$ {{ (plan.price * (1 - (currentCoupon?.percentage ?? 0) / 100)).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <VBtn
          rounded="full"
          block
          color="green"
          size="large"
          variant="flat"
          class="font-semibold"
          @click="goToNextStep"
        >
          <span class="text-white">Assinar agora</span>
        </VBtn>
      </div>
      <Fomo />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatCurrency } from "~/domains/global/libs/formatCurrency";
import { useCheckout } from "../composables/useCheckout";
import { useConfig } from "../composables/useConfig";
import Fomo from "./fomo.vue";

const { data } = useConfig();
const { currentPlanId, currentCoupon, selectPlan, goToNextStep } = useCheckout();
</script>
