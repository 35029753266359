import { useQueryClient } from "@tanstack/vue-query";
import { toast } from "vue3-toastify";
import { checkCouponQuery, type Coupon } from "../queries/checkCouponQuery";
import { useConfig } from "./useConfig";
import type { PaymentData } from "~/domains/account/queries/listPaymentsQuery";
import type { Plan } from "../queries/getCheckoutConfigsQuery";
import { useAuth } from "~/domains/auth/composables/useAuth";

const DEFAULT_PARAMS = {
  cpf: "",
  installments: 12,
  paymentType: "creditCard",
};

const currentPlanId = ref("anual");
const isLoadingCoupon = ref(false);
const currentCoupon = ref<Coupon | null>(null);
const hasCreditCardError = ref(false);
const currentPaymentData = ref<PaymentData | null>(null);

const params = reactive({
  ...DEFAULT_PARAMS,
});

export const useCheckout = () => {
  const queryClient = useQueryClient();
  const route = useRoute();
  const router = useRouter();
  const { wasPremium, isPremium } = useAuth()
  const { data } = useConfig();

  const isOpen = computed({
    get() {
      return route.query.assinar === "1";
    },
    set(value) {
      router.push({
        query: {
          ...route.query,
          etapa: undefined,
          assinar: value ? "1" : undefined,
        },
      });
    },
  });

  const currentPlan = computed(() => {
    const plan = data.value?.plans.find(
      (plan) => plan.id === currentPlanId.value
    );

    // Monkey patch
    if (!plan) {
      return {
        price: 0,
        maxInstallments: 0,
      };
    }

    return plan;
  });

  const currentStep = computed({
    get() {
      return parseInt((route.query.etapa as string) ?? "0");
    },
    set(value) {
      router.push({ query: { ...route.query, etapa: value } });
    },
  });

  const currentPrice = computed(() => {
    let couponDiscountMultiplier = 1;

    if (currentCoupon.value) {
      couponDiscountMultiplier = (100 - currentCoupon.value.percentage) / 100;
    }

    const totalPrice = currentPlan.value
      ? currentPlan.value.price * couponDiscountMultiplier
      : 0;

    return {
      oldMonthly: currentPlan.value!.price / params.installments,
      monthly: totalPrice / params.installments,
      oldTotal: currentPlan.value!.price,
      total: totalPrice,
    };
  });

  const applyCoupon = async (coupon: string) => {
    isLoadingCoupon.value = true;
    try {
      const data = await queryClient.fetchQuery({
        ...checkCouponQuery(coupon),
      });

      currentCoupon.value = data;

      return true;
    } catch (e) {
      toast.error("Cupom inválido");
    } finally {
      isLoadingCoupon.value = false;
    }
  };

  watch(
    () => params.paymentType,
    () => {
      hasCreditCardError.value = false;

      if (params.paymentType === "creditCard") {
        params.installments = currentPlan.value?.maxInstallments ?? 12;
      } else {
        params.installments = 1;
      }
    }
  );

  const init = () => {
    hasCreditCardError.value = false;
    currentPlanId.value = "anual";
    params.installments = 12;
    params.paymentType = "creditCard";

    if (isEligibleForRecover.value) {
      currentCoupon.value = {
        id: '6ac30399-fb78-4ce6-9a2b-2433df5f415f',
        percentage: 25,
        coupon: "DESCONTO REATIVAÇÃO",
      }
    }
  };

  const selectPlan = (plan: Plan) => {
    currentPlanId.value = plan.id;
    params.installments = plan.maxInstallments;
  };

  const maxInstallments = computed(() => {
    return currentPlan.value?.maxInstallments ?? 12;
  });

  const isEligibleForRecover = computed(() => {
    return wasPremium.value && !isPremium.value
  })

  return {
    maxInstallments,
    params,
    isOpen,
    currentStep,
    currentPlanId,
    currentPlan,
    isLoadingCoupon,
    currentCoupon,
    currentPrice,
    hasCreditCardError,
    currentPaymentData,
    isEligibleForRecover,
    init,
    selectPlan,
    goToNextStep: () => currentStep.value++,
    openCheckout: () => (isOpen.value = true),
    closeCheckout: () => (isOpen.value = false),
    applyCoupon,
  };
};
