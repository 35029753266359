<template>
  <VDialog
    :persistent="true"
    :model-value="isOpen"
    :close-on-back="false"
    max-width="500"
    title="Recuperar assinatura"
  >
  <div class="bg-white rounded-xl p-4">
    <img src="/images/icons/giftbox.svg" class="size-16 mx-auto my-4" />
    <h1 class="text-center">
      Você ganhou <b>25%</b> de desconto!
    </h1>
    <p class="text-center mb-4 mt-0.5">
      Reative seu acesso <b>⭐ PREMIUM</b> e libere todos os benefícios.
    </p>

    <Benefits />

    <VBtn @click="open" color="primary" class="w-full mt-6" rounded="full" flat>
      <span class="font-semibold">Reativar acesso PREMIUM</span>
    </VBtn>
    <VBtn @click="close" class="w-full mt-4" rounded="full" flat>
      Não, obrigado
    </VBtn>
  </div>
  </VDialog>
</template>

<script lang="ts" setup>
import { useAuth } from '~/domains/auth/composables/useAuth';
import { usePreference } from '~/domains/auth/composables/usePreference';
import { useCheckout } from '~/domains/checkout/composables/useCheckout';
import Benefits from './benefits.vue';

const { wasPremium } = useAuth()
const { openCheckout } = useCheckout()
const hasSeenRecover = usePreference({
  key: 'hasSeenRecover',
  defaultValue: false,
  cast: Boolean,
})

const isOpen = computed(() => {
  return !hasSeenRecover.value && wasPremium.value
})

const open = () => {
  openCheckout()
  hasSeenRecover.value = true
}

const close = () => {
  hasSeenRecover.value = true
}
</script>
