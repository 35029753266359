<template>
  <div
    class="bg-warning/90 mt-4 border-2 !border-black rounded-full p-2 px-4 flex flex-col text-center"
  >
    <div>
      <div v-if="!isEligibleForRecover" class="font-semibold text-lg">Oferta promocional</div>
      <div v-else class="font-semibold text-lg">
        Você ganhou <b>25%</b> de desconto!
      </div>
      <p class="text-sm">❗Últimos dias para aproveitar</p>
    </div>

    <div class="mx-auto flex gap-2 my-2">
      <div
        v-if="countdown.days > 0"
        class="bg-white border  text-center inline-flex flex-col p-2 rounded-full leading-none"
      >
        <div class="font-semibold min-w-8">{{ countdown.days }}d</div>
      </div>
      <div
        class="bg-white text-center inline-flex flex-col p-2 rounded-full leading-none"
      >
        <div class="font-semibold min-w-8">{{ countdown.hours }}h</div>
      </div>
      <div
        class="bg-white text-center inline-flex flex-col p-2 rounded-full leading-none"
      >
        <div class="font-semibold min-w-8">{{ countdown.minutes }}m</div>
      </div>
      <div
        class="bg-white text-center inline-flex flex-col p-2 rounded-full leading-none"
      >
        <div class="font-semibold min-w-8">{{ countdown.seconds }}s</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import { useCheckout } from "../composables/useCheckout";

const { isEligibleForRecover } = useCheckout()

const targetDate = computed(() => {
  // End of 2 days from now
  return dayjs().add(1, "day").endOf("day");
});

const timerActive = ref(true);
const countdown = reactive({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
});

const contabilizeCountdown = () => {
  const now = dayjs();
  const target = targetDate.value;

  let diff = target.diff(now, "second");

  countdown.days = Math.floor(diff / (60 * 60 * 24));
  diff -= countdown.days * (60 * 60 * 24);

  countdown.hours = Math.floor(diff / (60 * 60));
  diff -= countdown.hours * (60 * 60);

  countdown.minutes = Math.floor(diff / 60);
  diff -= countdown.minutes * 60;

  countdown.seconds = diff;
};

const handleCountdown = () => {
  contabilizeCountdown();

  setTimeout(() => {
    if (timerActive.value) {
      handleCountdown();
    }
  }, 500)
}

onMounted(() => {
  handleCountdown();
})

onBeforeUnmount(() => {
  timerActive.value = false;
})
</script>
