<template>
  <VCard>
    <VForm class="pt-4">
      <CheckoutPaymentInstallments
        v-model="params.installments"
        :max-installments="1"
        :total-price="currentPrice.total"
      />

      <VTextField
        v-model="params.cpf"
        label="CPF"
        placeholder="000.000.000-00"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        :disabled="isPending"
      />

      <VBtn
        color="green"
        block
        rounded="full"
        size="large"
        class="mt-4 font-semibold"
        :loading="isPending"
        @click="submit"
      >
        Pagar
      </VBtn>
    </VForm>
  </VCard>
</template>

<script lang="ts" setup>
import { useCheckout } from '../../composables/useCheckout'
import { usePaymentPixMutation } from '../../mutations/usePaymentPixMutation'
import { CheckoutPaymentInstallments } from '.'

const { mutate, isPending } = usePaymentPixMutation()

const { params, currentPlanId, currentCoupon, currentPrice } = useCheckout()

const submit = () => {
  mutate({
    cpf: params.cpf,
    planId: currentPlanId.value,
    affiliateId: currentCoupon.value?.id ?? null,
  })
}
</script>
