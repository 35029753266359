<template>
  <VCard>
    <VFadeTransition>
      <VAlert
        v-if="hasCreditCardError"
        type="error"
        class="my-4 rounded-lg"
        variant="outlined"
      >
        <div class=" flex flex-col">
          <b class="text-lg">Compra recusada.</b>
          <p>Não conseguimos completar o pagamento, pois a compra foi recusada pelo seu cartão.</p>
        </div>
      </VAlert>
    </VFadeTransition>

    <VForm
      ref="ccForm"
      class="pt-4"
    >
      <VTextField
        v-model="ccParams.cardNumber"
        v-maska:[masks.creditCard]
        label="Número do cartão"
        autocomplete="cc-number"
        placeholder="0000 0000 0000 0000"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        :rules="[
          validationRules.required,
        ]"
        :disabled="isPending"
      />
      <VTextField
        v-model="ccParams.cardName"
        label="Nome do titular"
        placeholder="Como no cartão"
        autocomplete="cc-name"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        :rules="[
          validationRules.required,
        ]"
        :disabled="isPending"
      />

      <div class="flex gap-2">
        <VTextField
          v-model="ccParams.cardExpiration"
          v-maska:[masks.date]
          label="Validade"
          placeholder="MM/AA"
          autocomplete="cc-exp"
          mask="date"
          variant="outlined"
          color="primary"
          density="comfortable"
          rounded
          :rules="[
            validationRules.required,
          ]"
          :disabled="isPending"
        />
        <VTextField
          v-model="ccParams.cardCvv"
          v-maska:[masks.cvv]
          label="CVV"
          placeholder="000"
          autocomplete="cc-csc"
          mask="cvv"
          variant="outlined"
          color="primary"
          density="comfortable"
          rounded
          :rules="[
            validationRules.required,
          ]"
          :disabled="isPending"
        />
      </div>

      <CheckoutPaymentInstallments
        v-model="params.installments"
        :total-price="currentPrice.total"
        :max-installments="maxInstallments"
        :disabled="isPending"
        :rules="[
          validationRules.required,
        ]"
      />

      <VTextField
        v-model="params.cpf"
        v-maska:[masks.cpf]
        label="CPF"
        placeholder="000.000.000-00"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        :rules="[
          validationRules.required,
        ]"
        :disabled="isPending"
      />

      <VBtn
        color="green"
        block
        rounded="full"
        size="large"
        class="mt-4 font-semibold"
        :loading="isPending"
        @click="submit"
      >
        Pagar
      </VBtn>
    </VForm>
  </VCard>
</template>

<script lang="ts" setup>
import { VFadeTransition } from 'vuetify/components'
import { useCheckout } from '../../composables/useCheckout'
import { usePaymentCreditCardMutation } from '../../mutations/usePaymentCreditCardMutation'
import { CheckoutPaymentInstallments } from '.'
import { validationRules } from '~/domains/auth/libs/validations'

const ccParams = reactive({
  cardNumber: '',
  cardName: '',
  cardExpiration: '',
  cardCvv: '',
})

const { params, currentPrice, maxInstallments, currentPlanId, currentCoupon, hasCreditCardError } = useCheckout()
const { mutate, isPending } = usePaymentCreditCardMutation()
const ccForm = ref()

const submit = async () => {
  // validate form
  if (!ccForm.value.validate()) return

  mutate({
    cardNumber: ccParams.cardNumber,
    cardName: ccParams.cardName,
    cardExpiration: ccParams.cardExpiration,
    cardCvv: ccParams.cardCvv,
    cpf: params.cpf,
    installments: params.installments,
    planId: currentPlanId.value,
    affiliateId: currentCoupon.value?.id ?? null,
  })
}

const masks = {
  creditCard: {
    mask: '#### #### #### ####',
  },
  cvv: {
    mask: ['###', '####'],
  },
  date: {
    mask: '##/##',
  },
  cpf: {
    mask: '###.###.###-##',
  },
}
</script>
